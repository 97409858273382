@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    /* background-color: rgba(235, 229, 229, 0.69);*/
       background-color:#f7f7f9;
       font-family: 'Lato', sans-serif;
}

